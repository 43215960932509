export enum EAskueMngStatus {
  "В работе" = "В работе",
  Отклонена = "Отклонена",
  Принята = "Принята",
}

export interface IAskueUpload extends FormData {
  append(name: "comment", value: string | Blob, file?: string): void;
}

export type IAskue = {
  sn: string;
  meter_name: string | null;
  dt_create: string | null; // "2023-05-15 16:26:24.330000"
  dt_verify: string | null; // "2023-05-19 15:27:25.323000"
  rele_status: number | null;
  address: string | null;
  port: number | null;
};

export interface IAskueRequest {
  page_num: number;
  obj_cnt: number;
  search?: string | undefined;
}

export type IAskueMeter = {
  meter_name: string;
  meter_count: number;
};

export type IAskuePort = {
  port: number;
  type: string;
};

export interface IAskueResponse {
  status: number; // 0 or 1
  time_schema: string;
  meter_count_all: number;
  meter_types: IAskueMeter[];
  meter_count_unverified: number;
  meter_new: number | null;
  server_name: string;
  active_ports: IAskuePort[];
  meter_rele_on?: number | null;
  meter_rele_off?: number | null;
}

export interface IAskueListResponse {
  comment: string;
  status: keyof typeof EAskueMngStatus;
  created_time: string;
  count: number;
  page_cnt: number;
  offset: number;
  limit: number;
  results: IAskue[];
}

export interface IAskueManagementResponse {
  appl_id: string;
  created_time: string; // "2022-06-20T10:00:00",
  comment?: string;
  tenant_name: string;
  status: EAskueMngStatus;
}

export interface IAskueMngUpdStatusRequest {
  appl_id: string;
  comment?: string;
  created_time?: string;
  status: keyof typeof EAskueMngStatus;
}

export interface IAskueEvent {
  datetime: string; // "2022-06-20T10:00:00"
  event: string;
  sn: string;
  meter_name: string;
  level: string;
}

export interface IAskueEventResponse {
  count: number;
  page_cnt: number;
  offset: number;
  limit: number;
  results: IAskueEvent[];
}

export interface IAskueEventRequest {
  sn?: string;
  meter_name?: string[];
  level?: number[];
  page_num: number;
  obj_cnt: number;
  period?: (null | string)[];
}
export interface IAskueEnergyRes {
  sn: string;
  meter_name: string;
  ActEpr: number;
  ActEobr: number;
  ReactEpr: number;
  ReactEobr: number;
  IDActEpr: number; // для запроса графика
  IDActEobr: number; // для запроса графика
  IDReactEpr: number; // для запроса графика
  IDReactEobr: number; // для запроса графика
  rele_status: boolean;
}

type IVar = string | null;
export interface IAskueEnergyReq {
  period: IVar[];
}

export interface IAskueChartReq {
  IDReactEpr?: number;
  IDActEpr?: number;
  IDActEobr?: number;
  IDReactEobr?: number;
  period: (null | string)[];
  interval: string;
}

type IAskueChartElem = {
  measuredate: string;
  value: number;
};

export interface IAskueChartRes {
  ActEpr: IAskueChartElem[];
  ReactEpr: IAskueChartElem[];
  ActEobr: IAskueChartElem[];
  ReactEobr: IAskueChartElem[];
}

export type IServChartData = {
  time: string; //  2023-07-31T11:52:11"
  value: number; // 1 or 0
};

export interface IAskueServer {
  activity_sessions: number;
  activity_time: number;
  report_server_activity: IServChartData[];
  version_pk: string;
  server_name: string;
}

export interface IAskueReleLogRes {
  datetime: string;
  event: string;
}
export interface ICheckDeviceResponse {
  id_pp: number;
  sn: string;
  city: string;
  street: string;
  house: string;
  flat: string;
}

export interface IDemoAskueCreateRequest {
  userName: string;
  phone: string;
  sn: string[];
}
